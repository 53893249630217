import React  from 'react';
import { useRef, useState } from "react";
import "./src/styles.css";
import useAudioRecorder from './AudioRecorder';
import MediaStreamRecorder from 'msr';
import WaveSurfer from 'wavesurfer.js';
import { SpeechClient } from '@google-cloud/speech';
// import WaveForm from "./src/Waveform";

function App() {
  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState('');
  const wavesurferRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const currentBlob = useRef(null);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaStreamRecorder(stream);
        mediaRecorderRef.current.mimeType = 'audio/wav'; // check this line for audio/wav
        mediaRecorderRef.current.audioChannels = 1;
        mediaRecorderRef.current.ondataavailable = function (blob) {
          // this function is called when stopRecording is called
          handleAudioData(blob);
        };
        mediaRecorderRef.current.start(3000);
        setIsRecording(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    currentBlob.current = null;
  };

  const handleAudioData = async (audioBlob) => {
    console.log(audioBlob);
    let combinedBlob;
    
    if (currentBlob.current != null)
      combinedBlob = new Blob([currentBlob.current, audioBlob], { type: 'audio/wav' });
    else
      combinedBlob = audioBlob;

    console.log(combinedBlob);
    const audioUrl = URL.createObjectURL(combinedBlob);
    if (!wavesurferRef.current) {
      wavesurferRef.current = WaveSurfer.create({
        container: '#waveform',
        waveColor: 'violet',
        progressColor: 'purple'
      });
    }
    wavesurferRef.current.load(audioUrl);

    currentBlob.current = combinedBlob;

    // Create a new FormData object and append the audio blob
    const formData = new FormData();
    formData.append('audio', audioBlob);
  
    // Transcribe the audio to text
    fetch('https://transcribe-n52ujiw4ba-uc.a.run.app', {
      method: 'POST',
      body: formData,
    })
    .then(response => response.text())
    .then(transcription => {
      setTranscript(transcription);
    })
    .catch(err => {
      console.error(err);
    });
  };

  return (
    <div>
      <button onClick={startRecording} type="button">Start</button>
      <button onClick={stopRecording} type="button">Stop</button>
      <div id="waveform"></div>
      <p>{transcript}</p>
    </div>
  );
}

export default App;